import { Meta } from "./meta.model"
import { environment } from 'src/environments/environment';

export class BaseModel {
    start_date:Date
    end_date:Date
    created:Date
    updated:Date
    meta:Meta[] = []

    public link1 = true
    public link2 = false
    public link1Active = 'active'
    public link2Active = ''

    constructor(
        start_date:string = null,
        end_date:string = null,
        public status:boolean = false,
        created_at:string = null,
        updated_at:string = null,
        meta:any = []
    ){
        this.start_date =  start_date ? new Date(start_date) : null;
        this.end_date =  end_date ? new Date(end_date) : null;
        this.created =  created_at ? new Date(created_at) : null;
        this.updated =  updated_at ? new Date(updated_at) : null;
        
        Object.keys(meta).forEach(key => {
            this.meta.push(new Meta(key, meta[key]))
        })
        // console.log(this.meta)
    }

    // 检查html中的img标签的src路径
    checkContent(html = ''){
        if(!html) return '';
        // var newContent= html.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi,function(match,capture){
        //   //capture,返回每个匹配的字符串
        //   var reg = RegExp(/http/);
        //   var srcUrl = capture

        //   if(!capture.match(reg)){
        //     srcUrl = environment.apiUrl + capture;       
        //   }
        //    return html.replace(new RegExp(capture, 'g'), srcUrl);
        // });
        // return newContent;


        // 检查html中的img标签的src路径
        var _html = html.replace(/(?=src=")[\s\S]+?(?=" alt)/g, (item) => {
            if(!item.match(/src="http/)){
                let tempSrc = item.replace('src="', "");
                return 'src="' + environment.apiUrl + tempSrc
            }else{
                return item
            }
        })
        return _html
    }

    tabSegment(tab = 'link1'){
        switch (tab) {
            case 'link1':
                this.link1 = true
                this.link1Active = 'active'
                this.link2 = false
                this.link2Active = ''

                break;
            case 'link2':
                this.link1 = false
                this.link1Active = ''
                this.link2 = true
                this.link2Active = 'active'
                break;
            default:
                this.link1 = true
                this.link2 = false
                break;
        }
    }
}
