import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {

  transform(imageurl: any, ...args: any[]): any {
    if(imageurl == null || imageurl == ""){
      return imageurl
    }
    if(window.location.host === 'bridal2.fdcpcrm.jp.s3-website-ap-northeast-1.amazonaws.com'){
      var awsUrl = 'bridal2.fdcpcrm.jp.s3-website-ap-northeast-1.amazonaws.com';
    }
    else{

      var awsUrl = environment.awsUrl

    }
    // }else{
    //   var awsUrl = "http://localhost:8080/"
    // }
    var reg = RegExp(awsUrl);
    if(!imageurl.match(reg)){
      return awsUrl + imageurl
    }
    return imageurl;
  }

}
