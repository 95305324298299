import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  message:"",
  confirm:false
}
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
  message:string = "";
  confirm:boolean = false
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.message = data.message
    this.confirm = data.confirm
  }
}
