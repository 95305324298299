import { BaseModel } from "./base.model";
import { Role } from "./role.model";

export class User extends BaseModel{
    constructor(
        public id:any = 0,
        public nickname:string = '',
        public email:string = '',
        public password:string = '',
        public phone:string = '',
        public avatar:string = '',
        public created_at:string = '',
        public role:Role = new Role(),
        public login_name:string = ''
    ){
        super();
    }

}
