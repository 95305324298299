import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http'
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EditorModule } from '@tinymce/tinymce-angular';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  // MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material';
// import { MatDatepickerModule } from '@angular/material/datepicker';

import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AppRoutes } from './app.routing';
import { AppDateAdapter, APP_DATE_FORMATS } from './services/format-datepicker';
import { EditorMatFormControlDirective } from './directives/editor-mat-form-control.directive';

///////
import { MatDatepickerModule, MatMomentDateModule,MAT_DATE_LOCALE ,MatDateFormats,MomentDateAdapter,MAT_DATE_FORMATS} from '@coachcare/datepicker';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { ImagesComponent } from './images/images.component';
import { DatexPipe } from './datex.pipe'
import { ImageUrlPipe } from './image-url.pipe';

export const MY_FORMATS: MatDateFormats = {
  parse: {
      datetime: 'DD.MM.YYYY HH:mm',
      date: 'DD.MM.YYYY',
      time: 'HH:mm'
  },
  display: {
      datetime: 'YYYY-MM-DD HH:mm:ss',
      date: 'DD.MM.YYYY',
      time: 'HH:mm',
      monthDayLabel: 'MMMM D',
      monthDayA11yLabel: 'MMMM D',
      monthYearLabel: 'MMMM YYYY',
      monthYearA11yLabel: 'MMMM YYYY',
      dateA11yLabel: 'LLLL',
      timeLabel: 'HH:mm'
  },
};
@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    EditorModule,
    DatexPipe,
    ImageUrlPipe
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'ja-JP'},
    // {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  declarations: [EditorMatFormControlDirective, DatexPipe, ImageUrlPipe],
})
export class MaterialModule {}

@NgModule({
    imports:      [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes,{
          useHash: true
        }),
        HttpModule,
        HttpClientModule,
        MaterialModule,
        MatNativeDateModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatDialogModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        ConfirmDialogComponent,
        ImagesComponent
    ],
    entryComponents: [
      ConfirmDialogComponent,
      ImagesComponent
    ],
    bootstrap:    [ AppComponent ]
})
export class AppModule { }
