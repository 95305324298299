import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'datex'
})
export class DatexPipe implements PipeTransform {

  transform(value: any,format: string = "MMM D, YYYY"): any {
    if(value == null) return null;
    var momentDate = moment(value);
    return  momentDate.format(format);
  }

}
