import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { GuardService } from 'src/app/services/guard.service'
export const AppRoutes: Routes = [
    {
      path: '',
      redirectTo: 'users',
      pathMatch: 'full',
    }, {
      path: '',
      component: AdminLayoutComponent,
      children: [
          {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate:[GuardService]
    }, {
        path: 'components',
        loadChildren: './components/components.module#ComponentsModule',
        canActivate:[GuardService]
    }, {
        path: 'forms',
        loadChildren: './forms/forms.module#Forms',
        canActivate:[GuardService]
    }, {
        path: 'fdcp',
        loadChildren: './fdcp/fdcp.module#FdcpModule',
        canActivate:[GuardService]
    }, {
        path: 'widgets',
        loadChildren: './widgets/widgets.module#WidgetsModule',
        canActivate:[GuardService]
    },{
        path: '',
        loadChildren: './userpage/user.module#UserModule',
        canActivate:[GuardService]
    },{
        path: '',
        loadChildren: './productpage/product.module#ProductModule',
        canActivate:[GuardService]
    }, {
        path: '',
        loadChildren: './timeline/timeline.module#TimelineModule',
        canActivate:[GuardService]
    }
  ]}, {
      path: '',
      component: AuthLayoutComponent,
      children: [{
        path: 'pages',
        loadChildren: './pages/pages.module#PagesModule'
      }]
    }
];
