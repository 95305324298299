import { Injectable } from '@angular/core';
// import { Headers, Http } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface ResponseObject {
    id: number;
  status: boolean;
  result: any
}

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  // private varHeaders: any = { headers: new Headers({ 'Content-Type': 'application/json',"Access-Control-Allow-Origin": "*", 'Accept': 'application/json' }) };

  private varHeaders = new HttpHeaders({"Access-Control-Allow-Origin": "*"})
  constructor(private http: HttpClient) { }

  get(path:string, data = {}):Observable<ResponseObject> {
    path = environment.apiUrl + path
   if (Object.keys(data).length != 0) {
      path = path + '?';
      for (let i = 0; i < Object.keys(data).length; i++) {
         let key = Object.keys(data)[i]
         path =  path + (i==0 ? '' : '&') +  key + "=" + data[key]
      }
   }
   console.log(path)

   return this.http.get<ResponseObject>(path)
              // .toPromise()
              // .then(response => response.json() )
              // .catch(this.handleError);
}

  post(path:string, data:any):Observable<ResponseObject> {
    path = environment.apiUrl + path
    return this.http
               .post<ResponseObject>(path, data,{headers:this.varHeaders})
              //  .toPromise()
              //  .then(res => res.json())
              //  .catch(this.handleError);
  }

  put(path:string, data:any):Observable<ResponseObject> {
    path = environment.apiUrl + path
      data._method = 'put';
      return this.http
                 .post<ResponseObject>(path, data,{headers:this.varHeaders})

   }

  delete(path:string, data:any):Observable<ResponseObject> {
    path = environment.apiUrl + path
      data._method = 'DELETE';
      return this.http
                 .post<ResponseObject>(path, data,{headers:this.varHeaders})
   }

  private handleError(error: any) {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
