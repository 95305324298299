import { Injectable } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/services/request.service';
import { Role } from '../models/role.model';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user:User;
  constructor(public router:Router,public request:RequestService,public dialog:MatDialog) { 

  }

  authenticate(){
    let id:string = localStorage.getItem('user_id');
    let avatar:string = localStorage.getItem('avatar');
    let nickname:string = localStorage.getItem('nickname');
    let login_name:string = localStorage.getItem('login_name');

    if (id && avatar && nickname) {
      this.user = new User(id, nickname, '', '', '', avatar, '', null,login_name);
      return true
    }
    return false
  }

  login(data:{id:number,name:string, email:string, avatar:string,roles:any,login_name:string,role:Role}){
    localStorage.setItem('user_id', String(data.id));
    localStorage.setItem('nickname', String(data.name));
    localStorage.setItem('avatar', data.avatar);
    localStorage.setItem('login_name', data.login_name);
    localStorage.setItem('role',data.role.slug);
    this.user = new User(data.id, data.name, data.email, '', '', data.avatar, '', data.role,data.login_name);
  }

  logout(){
    localStorage.removeItem('user_id')
    localStorage.removeItem('avatar')
    localStorage.removeItem('nickname')
    localStorage.removeItem('email')
    localStorage.removeItem('login_name')
    localStorage.removeItem('role')

    this.user = null;
    console.log('user logout')
    return true;
  }

  getLoginstatus(){
    var login_user_id = localStorage.getItem('user_id');
    this.request.get('api/fdcpbr/loginstatus',{login_user_id:login_user_id}).subscribe((res:any)=>{
      if(res.status){
        if(!res.result.role){
          this.router.navigate(['pages/login']);
          this.logout()
        }else{
          var user = res.result
          let data = {id:user.id,name:user.nickname, email:user.email, avatar:user.avatar,roles:user.roles,login_name:user.login_name,role:user.role}
          this.login(data);
          console.log(this.user)
        }
      }else{
        this.router.navigate(['pages/login']);
        this.logout()
      }
    })
  }

  checkAuth(){
    return new Promise(resolve=>{
      var role  = localStorage.getItem('role')
      if(role){
        if(role == 'shop'){
          this.dialog.open(ConfirmDialogComponent, {
            width: '250px',
            data: {
              message: "管理権限がありません"
            }
          });
          resolve(false)
        }
      }
      resolve(true)
    })
  }
}
