import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GuardService {

  constructor(public auth:AuthService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    
  ) {
    const id = next.paramMap.get('id');

    if(!this.auth.authenticate()){
      return false;
    }

    return true;
  }
}
