import { Component, OnInit,Inject } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface ImageData {
  type:null,
  showFooter:false,
  showFilename:false
}
@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css']
})
export class ImagesComponent implements OnInit {
  images = []
  type = null;
  showFooter = false
  showFilename = false
  constructor(public request:RequestService,@Inject(MAT_DIALOG_DATA) public data: ImageData) {
    this.type = data.type
    this.showFooter = data.showFooter
    this.showFilename = data.showFilename
   }

  ngOnInit() {
    this.imageList()
  }

  imageList(){
    console.log(this.type)
    this.request.get('api/fdcpbr/images?type='+this.type).subscribe(res=>{
      if(res.status){
        this.images = res.result.data
        console.log(this.images)
      }
    })
  }

  selectImage(image){
    console.log(image)
  }

}
