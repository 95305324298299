export class Role{
    constructor(
        public id:any = 0,
        public name:string = '',
        public slug:string = ''
    ){
        
    }

}
