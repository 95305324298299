import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/services/request.service';

// declare const $: any;
import * as $ from 'jquery';
//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    icontype: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
    // {
    //     path: '/dashboard',
    //     title: 'Dashboard',
    //     type: 'link',
    //     icontype: 'dashboard'
    // }
    ,{
        // path: '/users',
        // title: '会員管理',
        // type: 'link',
        // icontype: 'person'


        path: '/',
        title: 'ユーザー管理',
        type: 'sub',
        icontype: 'apps',
        collapse: 'users',
        children: [
            {path: 'users', title: '社員管理', icontype:'person'}
        ]

    },{
        path: '/products',
        title: '商品管理',
        type: 'link',
        icontype: 'grid_on'

    },{
        path: '/fdcp',
        title: 'BRIDAL',
        type: 'sub',
        icontype: 'apps',
        collapse: 'components',
        children: [
            {path: 'banners', title: 'バナー管理', icontype:'grid_on'},
            {path: 'stores', title: '店舗管理', icontype:'grid_on'},
            {path: 'campaigns', title: 'フェア管理', icontype:'grid_on'},
            {path: 'customer-voices', title: 'お客様の声', icontype:'grid_on'},
            {path: 'news', title: 'インフォメーション', icontype:'grid_on'},
            {path: 'faqs', title: 'よくあるご質問', icontype:'grid_on'},
            {path: 'glossary', title: '用語集', icontype:'grid_on'},
            {path: 'blogs', title: 'Bridal journal', icontype:'grid_on'},
            {path: 'pages', title: 'ページ管理', icontype:'grid_on'},
        ]
    }
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ps: any;
    isshowCollapseExample = false

    constructor(public auth: AuthService,private router:Router,private request:RequestService) {

    }
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
    }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
    logout(){
        this.request.post('api/fdcpbr/logout','').subscribe(res => {
            if(res.status){
                if(this.auth.logout()){
                    console.log('logout success')
                    this.router.navigate(['pages/login']);
                }
            }
        })
    }

    showCollapseExample(){
        this.isshowCollapseExample = !this.isshowCollapseExample
    }

    clear(path){
       switch (path) {
           case 'banners':
               localStorage.removeItem('bannerParams')
               break;
            case 'blogs':
               localStorage.removeItem('blogParams')
               break;
            case 'campaigns':
                localStorage.removeItem('campaignParams')
            break;
            case 'customer-voices':
                localStorage.removeItem('voiceParams')
            break;
            case 'faqs':
                localStorage.removeItem('faqParams')
            case 'glossary':
                localStorage.removeItem('glossaryParams')    
            break;
            case 'news':
                localStorage.removeItem('newsParams')    
            break;
            case 'stores':
                localStorage.removeItem('storeParams')    
            break;
            case '/products':
                localStorage.removeItem('productParams')    
            break;
            case 'pages':
                localStorage.removeItem('pageParams')    
            break;
           default:
               break;
       }
    }
}
